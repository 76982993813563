// Include Bootstrap components
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import { Dropdown, Collapse, Alert, Popover } from 'bootstrap'

// Include Cookie helper
import Cookies from 'js-cookie'

// Include Rails UJS
import RailsUJS from '@rails/ujs'

// eslint-disable-next-line no-underscore-dangle
if (!window._rails_loaded) {
  RailsUJS.start()
}

// Enable popovers
const popoverTriggerList = document.querySelectorAll(
  '[data-bs-toggle="popover"]'
)
Array.from(popoverTriggerList).map(
  // eslint-disable-next-line no-undef
  popoverTriggerEl => {
    const popover = new Popover(popoverTriggerEl)

    // Show popover if cookie is present and elemet is visible
    if (
      popoverTriggerEl.dataset.bsShowOnCookie &&
      popoverTriggerEl.offsetParent !== null
    ) {
      if (Cookies.get(popoverTriggerEl.dataset.bsShowOnCookie)) {
        popover.show()
      }
    }

    if (popoverTriggerEl.dataset.bsHideOn) {
      popoverTriggerEl.addEventListener(
        popoverTriggerEl.dataset.bsHideOn,
        () => {
          popover.hide()

          if (popoverTriggerEl.dataset.bsShowOnCookie) {
            Cookies.remove(popoverTriggerEl.dataset.bsShowOnCookie)
          }
        }
      )
    }

    return popover
  }
)
